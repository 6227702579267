<template>
    <div class="col-right">
        <a-card>
            <div slot="title">
                <div class="page-right-title">
                    <div class="item-title">地址管理</div>
                </div>
            </div>
            <div class="row-btn">
                <a-button type="primary" @click="showAddModal">新建地址</a-button>
            </div>
            <a-spin :spinning="spinning">

                <div class="address-list">
                    <ul>
                        <li v-for="(item, idx) in addressList" :key="item.id">
                            <div class="item-left">
                                <dl>
                                    <dt>
                                        <div class="item-name">{{item.name}}</div>
                                        <div class="item-phone">{{item.mobile}}</div>
                                        <div class="item-tag" v-if="item.is_default">默认</div>
                                    </dt>
                                    <dd>{{item.province}}{{item.city}}{{item.area}} {{item.detail_address}}</dd>
                                </dl>
                            </div>
                            <div class="item-right">
                                <a class="ant-btn ant-btn-link" @click="showEditModal(idx)">编辑</a>
                                <a-popconfirm
                                        title="是否确定删除该地址？"
                                        ok-text="确定"
                                        cancel-text="取消"
                                        @confirm="confirmDel(idx)"
                                >
                                    <a class="ant-btn ant-btn-link">删除</a>
                                </a-popconfirm>
                            </div>
                        </li>
                    </ul>
                </div>
            </a-spin>
        </a-card>
        <a-modal
                :dialog-style="{ top: '100px' }"
                width="860px"
                :visible="showAddressCreateModal"
                @cancel="() => {showAddressCreateModal = false}"
                title="新建地址"
                :footer="false"
        >
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" :form="form" @submit="handleSubmit">
                <a-form-item label="姓名" required>
                    <a-input placeholder="请输入姓名"
                             v-decorator="[
                                'name',
                                {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入姓名'
                                    }
                                ]}
                            ]"
                    ></a-input>
                </a-form-item>
                <a-form-item label="手机号码" required>
                    <a-input placeholder="请输入手机号码"
                             v-decorator="[
                                'mobile',
                                {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入姓名'
                                    }
                                ]}
                            ]"
                    ></a-input>
                </a-form-item>
                <a-form-item label="所在地区" required>
                    <a-row :gutter="8">
                        <a-col :span="8">
                            <a-select size="large" placeholder="请选择" @change="changeProvince"
                                      v-decorator="[
                                                'province_code',
                                                {
                                                    initialValue:province_code,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                            >
                                <a-select-option v-for="item in provinceList" :key="item.regionCode"
                                                 :value="item.regionCode">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="8">
                            <a-select v-if="cityList.length > 0" size="large" placeholder="请选择"
                                      @change="changeCity"
                                      v-decorator="[
                                                'city_code',
                                                {
                                                    initialValue:city_code,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                            >
                                <a-select-option v-for="item in cityList" :key="item.regionCode"
                                                 :value="item.regionCode">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="8">
                            <a-select v-if="areaList.length > 0" size="large" placeholder="请选择"
                                      @change="changeArea"
                                      v-decorator="[
                                                'area_code',
                                                {
                                                    initialValue:area_code,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                            >
                                <a-select-option v-for="item in areaList" :key="item.regionCode"
                                                 :value="item.regionCode">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </a-form-item>
                <a-form-item label="详细地址" required>
                    <a-input placeholder="请输入详细地址,街道,门牌号等"
                             v-decorator="[
                                    'detail_address',
                                    { rules: [
                                        {
                                            required: true,
                                            message: '请输入详细地址,街道,门牌号等'
                                        }
                                    ]}
                                ]"
                    ></a-input>
                </a-form-item>
                <a-form-item class="footer" style="text-align: right;">
                    <a-checkbox
                                v-decorator="['is_default', {valuePropName:'checked',initialValue:false}]"
                    >
                        设为默认地址
                    </a-checkbox>
                    <a-button type="primary" html-type="submit">
                        保存
                    </a-button>
                    <a-button @click="()=>{showAddressCreateModal = false;}">取消</a-button>
                </a-form-item>
            </a-form>
        </a-modal>

        <a-modal
            :dialog-style="{ top: '100px' }"
            width="860px"
            :visible="showAddressEditModal"
            @cancel="() => {showAddressEditModal = false}"
            title="新建地址"
            :footer="false"
        >
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" :form="editForm" @submit="updateSubmit">
                <a-form-item label="姓名" required>
                    <a-input placeholder="请输入姓名"
                             v-decorator="[
                                'name',
                                {
                                    initialValue: address.name,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入姓名'
                                    }
                                ]}
                            ]"
                    ></a-input>
                </a-form-item>
                <a-form-item label="手机号码" required>
                    <a-input placeholder="请输入手机号码"
                             v-decorator="[
                                'mobile',
                                {
                                    initialValue: address.mobile,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入姓名'
                                    }
                                ]}
                            ]"
                    ></a-input>
                </a-form-item>
                <a-form-item label="所在地区" required>
                    <a-row :gutter="8">
                        <a-col :span="8">
                            <a-select size="large" placeholder="请选择" @change="changeProvince"
                                      v-decorator="[
                                                'province_code',
                                                {
                                                    initialValue:address.province_code,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                            >
                                <a-select-option v-for="item in provinceList" :key="item.regionCode"
                                                 :value="item.regionCode">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="8">
                            <a-select v-if="cityList.length > 0" size="large" placeholder="请选择"
                                      @change="changeCity"
                                      v-decorator="[
                                                'city_code',
                                                {
                                                    initialValue:address.city_code,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                            >
                                <a-select-option v-for="item in cityList" :key="item.regionCode"
                                                 :value="item.regionCode">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="8">
                            <a-select v-if="areaList.length > 0" size="large" placeholder="请选择"
                                      @change="changeArea"
                                      v-decorator="[
                                                'area_code',
                                                {
                                                    initialValue:address.area_code,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '请选择'
                                                    }
                                                ]}
                                            ]"
                            >
                                <a-select-option v-for="item in areaList" :key="item.regionCode"
                                                 :value="item.regionCode">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </a-form-item>
                <a-form-item label="详细地址" required>
                    <a-input placeholder="请输入详细地址,街道,门牌号等"
                             v-decorator="[
                                    'detail_address',
                                    {
                                        initialValue: address.detail_address,
                                        rules: [
                                        {
                                            required: true,
                                            message: '请输入详细地址,街道,门牌号等'
                                        }
                                    ]}
                                ]"
                    ></a-input>
                </a-form-item>
                <a-form-item class="footer" style="text-align: right;">
                    <a-checkbox
                        v-decorator="['is_default', {valuePropName:'checked',initialValue:address.is_default == 1}]"
                    >
                        设为默认地址
                    </a-checkbox>
                    <a-button type="primary" html-type="submit">
                        保存
                    </a-button>
                    <a-button @click="()=>{showAddressEditModal = false;}">取消</a-button>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    export default {
        name: "setting-address",
        data() {
            return {
                spinning: false,
                showAddressCreateModal: false,
                showAddressEditModal: false,
                addressList: [],
                address:{},
                provinceList: [],
                cityList: [],
                areaList: [],
                province_code: undefined,
                city_code: undefined,
                area_code: undefined
            }
        },
        created() {
            this.uploadUrl = this.$apiBaseURL + '/api/index/uploadImg'
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.getRegionByParent(0, 1);
            this.getList();
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'register'});
            this.editForm = this.$form.createForm(this, {name: 'editForm'});
        },
        methods: {
            showAddModal(){
                this.showAddressCreateModal = true;
                this.cityList = [];
                this.areaList = [];
            },
            showEditModal(idx){
                this.address = this.addressList[idx];

                this.getRegionByParent(this.address.province_code, 2);
                this.getRegionByParent(this.address.city_code, 3);
                this.showAddressEditModal = true;
            },
            updateSubmit(e){
                e.preventDefault();
                this.editForm.validateFieldsAndScroll((err, values) => {
                    console.log(values)
                    if (!err) {
                        this.provinceList.forEach(item => {
                            if(item.regionCode == values.province_code){
                                values.province = item.name;
                            }
                        })
                        this.cityList.forEach(item => {
                            if(item.regionCode == values.city_code){
                                values.city = item.name;
                            }
                        })
                        this.areaList.forEach(item => {
                            if(item.regionCode == values.area_code){
                                values.area = item.name;
                            }
                        })
                        values.id = this.address.id;
                        service.post(service.uri.address.edit, values).then(res => {
                            if(res.code == 200){
                                this.showAddressEditModal = false;
                                this.getList();
                            }
                        });
                    }
                });
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    console.log(values)
                    if (!err) {
                        this.provinceList.forEach(item => {
                            if(item.regionCode == values.province_code){
                                values.province = item.name;
                            }
                        })
                        this.cityList.forEach(item => {
                            if(item.regionCode == values.city_code){
                                values.city = item.name;
                            }
                        })
                        this.areaList.forEach(item => {
                            if(item.regionCode == values.area_code){
                                values.area = item.name;
                            }
                        })
                        service.post(service.uri.address.add, values).then(res => {
                            if(res.code == 200){
                                this.showAddressCreateModal = false;
                                this.getList();
                            }
                        });
                    }
                });
            },
            changeProvince(e) {
                this.cityCode = undefined;
                this.cityList = [];
                this.areaList = [];
                this.getRegionByParent(e, 2);
            },
            changeCity(e) {
                this.areaCode = undefined;
                this.areaList = [];
                this.getRegionByParent(e, 3);
            },
            changeArea(e) {
                console.log(e)
            },
            getRegionByParent(parentCode, level) {
                service.post(service.uri.company.getRegionByParent, {parentCode: parentCode}).then(res => {
                    if (res.code == 200) {
                        if (level == 1) {
                            this.provinceList = res.data.list;
                        } else if (level == 2) {
                            this.cityList = res.data.list;
                        } else if (level == 3) {
                            this.areaList = res.data.list;
                        }
                    }
                });
            },
            getList(){
                this.spinning = true;
                service.post(service.uri.address.getList).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.addressList = res.data.list;
                    }
                })
            },
            confirmDel(e) {
                console.log(e)
                console.log(this.addressList[e].id)
                service.post(service.uri.address.del, {id: this.addressList[e].id}).then(res => {
                    if(res.code == 200){
                        this.getList();
                        this.addressList.splice(e);
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .row-btn {
        padding: 10px 0 30px;
    }

    .address-list {
        li {
            display: flex;
            align-items: center;
            margin-bottom: 9px;
            border: 1px solid #ededed;
            padding: 17px 30px;

            .item-left {
                flex: 1;

                dl {
                    dt {
                        display: flex;
                        align-items: center;

                        .item-name {
                            font-size: 16px;
                            color: #333;
                            font-weight: 400;
                        }

                        .item-phone {
                            font-size: 14px;
                            color: #666;
                            padding: 0 10px 0 27px;
                        }

                        .item-tag {
                            font-size: 12px;
                            color: #de0000;
                            width: 32px;
                            height: 20px;
                            background: #ffe5e5;
                            line-height: 20px;
                            text-align: center;
                        }
                    }

                    dd {
                        padding-top: 18px;
                        font-size: 12px;
                        line-height: 20px;
                        color: #999;
                    }
                }
            }

            .item-right {
                padding: 0 0 0 20px;

                .ant-btn-link {
                    color: #365fe3;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
</style>